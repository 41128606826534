import {
  ALGOLIA_API_KEY,
  ALGOLIA_APP_ID,
  ALGOLIA_VAKI_INDEX,
  API_URL,
  APP_ID,
  FEATURE_FLAGS,
  HOST_URL,
  MP_API_KEY,
  MP_NOTIFICATION_URL,
  STRIPE_KEY,
  STRIPE_KEY_MX,
  PAYPAL_MX_CLIENT_ID
} from '@vaki/shared/util/environment';
import { environment } from '../environments/environment';

export const envKeys = [
  { provide: APP_ID, useValue: environment.appVersion },
  { provide: ALGOLIA_API_KEY, useValue: environment.algolia.apiKey },
  { provide: ALGOLIA_APP_ID, useValue: environment.algolia.appId },
  { provide: ALGOLIA_VAKI_INDEX, useValue: environment.algolia.indexes.vakis },
  { provide: HOST_URL, useValue: environment.hostUrl },
  { provide: API_URL, useValue: environment.apiVakiURL },
  { provide: STRIPE_KEY_MX, useValue: environment.stripe_key_mx },
  { provide: STRIPE_KEY, useValue: environment.stripe_key },
  {
    provide: MP_NOTIFICATION_URL,
    useValue: environment.mercadopago.notification_url
  },
  { provide: MP_API_KEY, useValue: environment.mercadopago.key },
  { provide: FEATURE_FLAGS, useValue: environment.featureFlagsDefault },
  { provide: PAYPAL_MX_CLIENT_ID, useValue: environment.paypal_mx.client_id }
];
